<template>
  <div class="wh-space-bottom">
    <b-card>
      <table-search
        ref="search"
        v-model="filterValue"
        :fields="search_fields"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </b-card>

    <b-card>
      <table-header
        ref="header"
        :title="title"
        :button-actions="header_actions"
        @runSearch="runSearch"
        :hide-search="false"
        :search-tips="$t('call_bell.nfc_search_tips')"
      />
      <table-content
        :per-page="page_condition.pageSize"
        :per-page-dropdown-enabled="true"
        @perPageChange="perPageChange"
        :columns="columns"
        :rows="rows"
        :total-rows="total_rows"
        :current-page="current_page"
        :action-options="table_actions"
        :permission="view_permission"
        @runTableAction="runTableAction"
        @sortChange="sortChange"
        @pageChange="pageChange"
      />
    </b-card>
  </div>
</template>

<script>
import common from '@/common'
import TableContent from '@/components/TableContent'
import TableHeader from '@/components/TableHeader'
import TableSearch from '@/components/TableSearch'

export default {
  name: 'callBellNfcManagement',
  components: {
    TableSearch,
    TableHeader,
    TableContent,
  },
  data() {
    return {
      title: common.getMenuName('callBellNfcManagement'),
      view_permission: common.checkPermission('CallBell_Menu_NFC_Manage'),
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage()
      },
      columns: [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: 'call_bell.nfc_tag',
          field: 'nfcTag',
          // sortable: false,
          width: '190px',
          changeHTML: (value, row) => `<span class="wh-font-family-monospace">${value}</span>`
        },
        {
          label: 'staff.staffCode',
          field: 'staffCode',
          // sortable: false,
          width: '110px',
        },
        {
          label: 'member.chineseName',
          field: 'chineseName',
          width: '110px',
        },
        {
          label: 'staff.englishName',
          field: 'englishName',
          width: '150px',
        },
        {
          label: 'staff.isActive',
          field: 'isActive',
          width: '70px',
          change: function (isActive) {
            return isActive ? common.getI18n('staff.active') : common.getI18n('staff.passive')
          }
        },
        // {
        //   label: 'admin.isAdmin',
        //   field: 'isAdmin',
        //   change: function (isAdmin) {
        //     return isAdmin ? common.getI18n('common.yes') : common.getI18n('common.no')
        //   }
        // },
        {
          label: 'common.action',
          field: 'action',
          width: '100px',
          sortable: false,
        },
      ],
      header_actions:[],
      table_actions: [
        {
          text: 'common.delete',
          variant: 'danger',
          fun: 'deleteData',
          permission: 'CallBell_NFC_Delete'
        }
      ],
      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          // clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'facilityID',
          label: 'common.facility',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'isActive',
          label: 'staff.isActive',
          type: 'select',
          options: [
            {text: this.$t('staff.active'), value: true},
            {text: this.$t('staff.passive'), value: false},
          ]
        },
      ],
      filterValue: {
        // companyID: common.isAdmin() ? null : common.getCompanyId(),
        // facilityID: common.isAdmin() ? null : common.getFacilityId(),
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId(),
      },
    }
  },

  activated() {
    if (!common.isAdmin()) {
      common.getSearchFloorOptions('floorID', common.getRole().facilityID, this)
      this.getList()
    } else {
      common.getSearchCompanyOptions('companyID', this)
      common.getSearchFacilityOptions('facilityID', this.filterValue.companyID, this)
      common.getSearchFloorOptions('floorID', this.filterValue.facilityID, this)
      this.getList()
    }
  },
  deactivated() {
    this.$refs.search.resetFilterValueToFiltered()
  },

  methods: {
    getList: function () {
      if (!this.view_permission) return

      const params = Object.assign({}, this.getSearchCondition(), this.sort_condition, this.page_condition)
      if (params.companyID && !params.facilityID) {
        params.facilityID = -1
      }
      this.$requestWehealth({
        method: 'get',
        url: '/staffnfc/GetNfcList',
        params
      })
        .then(res => {
          this.total_rows = res.data.total
          this.rows = res.data.nfcs
        })
    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()
      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      Object.assign(where, this.$refs.search.search_condition)
      return where
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },

    runSearch() {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      this.getList()
    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false
      })
    },

    runTableAction: function (action, data) {
      this[action](data)
    },
    changeTimeFormat(time) {
      if (time === null) {
        return ''
      } else {
        return this.$moment(time)
          .format('YYYY-MM-DD HH:mm:ss')
      }
    },
    deleteData: function (data) {
      common.showConfirm({
        title: this.$t('common.delete_tips') + data.nfcTag,
        confirm_fun: async () => {
          await this.$requestWehealth({
            method: 'post',
            url: '/staffnfc/DeleteNfc',
            params: {
              nfcID: data.nfcID
            }
          })
          common.showToast({ title: this.$t('common.delete_success') })
          this.getList()
        }
      })
    },
  }
}
</script>
